<template>
  <section class="tables">
    
    <div class="page-header">
      <h3 class="page-title">Invoice Services</h3>
      <nav aria-label="breadcrumb">
        <div class="float-right">
          <router-link class="btn btn btn-add btn-primary" :to="`/settings/add-invoice`">Add New
          </router-link>
        </div>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="table-responsive mb-0 ">
            <table class="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Currency Symbol</th>
                  <th>Currency Code</th>
                  <th>Currency Format</th>
                  <th>Active</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="invoice in invoice" :key="invoice.id">
                  <td>{{ invoice.name }}</td>
                  <td>{{ invoice.currency_symbol }}</td>
                  <td>{{ invoice.currency_code }}</td>
                  <td>
                    <span v-if="invoice.currency_format == '{VALUE}{SYMBOL}'">{{ invoice.currency_code }}&nbsp;{{
                        invoice.currency_symbol
                    }}</span>
                    <span v-if="invoice.currency_format == '{SYMBOL}{VALUE}'">{{ invoice.currency_symbol }}&nbsp;{{
                        invoice.currency_code
                    }}</span>
                  </td>
                  <td>
                    <label class="switch">
                      <input type="checkbox" @change="onToggleChange(invoice.id, invoice.active)"
                        v-model="invoice.active" />
                      <span class="slider round"></span> </label><br /><br />
                  </td>
                  <td>
                    <router-link  class="mr-2 table-icon" v-b-tooltip.hover title="Edit" :to="`/settings/edit-invoice/${invoice.id}`">
                      <i class="mdi mdi-pencil "></i>
                    </router-link>
                    <!-- <a v-on:click="deleteFile(invoice.id)"  class="mr-2 table-icon" v-b-tooltip.hover title="Delete">
                      <i class="mdi mdi-delete"></i>
                    </a> -->
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="col-12 p-3 text-center" v-if="invoice == ''">
              No data found!
            </div>
            <div style="border-top: 1px solid #e9ecef" v-if="invoice">
              <paginate  :page-count="totalPage" :page-range="3" :margin-pages="2"
              :click-handler="clickCallback" :prev-text="'Prev'" :next-text="'Next'" :container-class="'pagination'"
              :page-class="'page-item'">
            </paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Paginate from 'vuejs-paginate'
import { setingsApi } from "../../../api";

export default {
  name: "invoice-list",
  components: {
    Paginate
  },
  data() {
    return {
      invoice: "",
      totalPage: this.totalPage,
      
    };
  },
  mounted() {
    let page = 1;
    this.invoiceList(page);
  },
  methods: {
    async invoiceList(page) {
      
      const { data } = await setingsApi.invoiceServices(page);
      this.invoice = data.data.data.data;
      
      this.totalPage = data.data.data.last_page;
      this.current_page = data.data.data.current_page;
    },
    clickCallback: function (page) {
      this.invoiceList(page) 
    },
    isPageActive(invoice) {
      return this.current_page === invoice;
    },
    onClickPage(invoice) {
      this.invoiceList(invoice);
    },
    async onToggleChange(id, event) {
      if (event == true) {
        event = 1;
        console.log(event);
      } else {
        event = 0;
      }
      this.body = { active: event };
      
      const data = await setingsApi.updateInvoice(id, {
        ...this.body,
      });
      
      this.invoiceList(1);
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.messages,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.messages,
        });
      }
    },
    deleteFile(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          
          const response = setingsApi.deleteInvoice(id).then((response) => {
            
            if (response.data.code == 200) {
              this.invoiceList(1);
              if (result.value) {
                this.$swal(
                  "Deleted!",
                  "Your file has been deleted.",
                  "success"
                );
              }
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped>
</style>
